.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #c0c3c4;
}

.Menu-icon {
  position: absolute;
  left: 1%;
  top: 2%;
  right: 0;
}
